import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

const Box = styled.div`
	padding: 20px;
	background: lemonchiffon;

	pre {
		white-space: pre-wrap;
	}
`

export default class Team extends Component {
	render() {
		const {
			data: {
				craft: { entry }
			}
		} = this.props

		return (
			<div className="px-4 bg-blue-light">
				<Link to="/about">Back to Team</Link>
				<h1>{entry.title}</h1>
				<Box>
					<pre>{JSON.stringify(entry, null, 2)}</pre>
				</Box>
			</div>
		)
	}
}

export const teamQuery = graphql`
	query QueryTeam($id: [Int]!) {
		craft {
			entry(id: $id) {
				... on Craft_Team {
					title
					firstName
					lastName
					body {
						content
					}
					jobTitle
					intern
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
				}
			}
		}
	}
`
